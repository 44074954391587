
















































import { Component, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/constants/enums/routeNames';
import ContentRow from '@/components/contentRow.vue';
import ContentCard from '@/components/contentCard.vue';
import { isMobile, isMobileSafari } from 'mobile-device-detect';
import Btn from '@/components/btn.vue';
import Modal from '@/components/modal.vue';
import { ehypLinks } from '@/config/config';

@Component({
    name: RouteNames.SERVICE,
    components: {
        Modal,
        Btn,
        ContentCard,
        ContentRow,
    },
})
export default class Service extends Vue {
    private isMobile = isMobile;
    private isMobileSafari = isMobileSafari;
    private isInterestCalculatorModalOpen = false;
    private isMortgageRequestModalOpen = false;
    private interestCalculatorUrl = ehypLinks.interestCalculatorUrl;
    private mortgageRequestUrl = ehypLinks.mortgageRequestUrl;

    private openInterestCalculatorModal() {
        if (this.isMobileSafari) {
            this.openTab(this.interestCalculatorUrl);
        } else {
            this.isInterestCalculatorModalOpen = true;
        }
    }

    private openMortgageRequestModal() {
        if (this.isMobileSafari) {
            this.openTab(this.mortgageRequestUrl);
        } else {
            this.isMortgageRequestModalOpen = true;
        }
    }

    private openTab(url: string) {
        // Create link in memory
        const a = window.document.createElement('a');
        a.target = '_blank';
        a.href = url;

        // Dispatch fake click
        const e = window.document.createEvent('MouseEvents');
        e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
    }
}
