import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AboutMe from '@/views/AboutMe.vue';
import Consultation from '@/views/Consultation.vue';
import Contact from '@/views/Contact.vue';
import Home from '../views/Home.vue';
import Impress from '@/views/Impress.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import Service from '@/views/Service.vue';
import { RouteNames } from '@/constants/enums/routeNames';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: Home,
    },
    {
        path: '/uebermich',
        name: RouteNames.ABOUT_ME,
        component: AboutMe,
    },
    {
        path: '/beratung',
        name: RouteNames.CONSULTATION,
        component: Consultation,
    },
    {
        path: '/service',
        name: RouteNames.SERVICE,
        component: Service,
    },
    {
        path: '/kontakt',
        name: RouteNames.CONTACT,
        component: Contact,
    },
    {
        path: '/impressum',
        name: RouteNames.IMPRESS,
        component: Impress,
    },
    {
        path: '/datenschutz',
        name: RouteNames.PRIVACY_POLICY,
        component: PrivacyPolicy,
    },
];

const router = new VueRouter({
    routes,
});

export default router;
