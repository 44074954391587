

































import { Component, Vue } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
import logoSvg from '@/../public/img/logo.svg?inline';
import { NavigationItem } from '@/constants/interfaces/Navigation';
import { RouteNames } from '@/constants/enums/routeNames';
import Navigation from '@/components/navigation.vue';
import { Actions, Getters } from '@/constants/enums/store';
import { Route } from 'vue-router';
import { Getter } from 'vuex-class';
import { isMobile } from 'mobile-device-detect';

@Component({
    name: ComponentName.TOP_HEADER,
    components: {
        Navigation,
        logoSvg,
    },
})
export default class TopHeaderComponent extends Vue {
    @Getter(Getters.currentRoute)
    private currentRoute!: Route;

    private isMobile = isMobile;

    private navigationItems: NavigationItem[] = [
        {
            label: 'Über mich',
            to: { name: RouteNames.ABOUT_ME },
        },
        {
            label: 'Beratung',
            to: { name: RouteNames.CONSULTATION },
        },
        {
            label: 'Startseite',
            to: { name: RouteNames.HOME },
        },
        {
            label: 'Service',
            to: { name: RouteNames.SERVICE },
        },
        {
            label: 'Kontakt',
            to: { name: RouteNames.CONTACT },
        },
    ];

    private get isHomeRoute(): boolean {
        return this.currentRoute ? this.currentRoute.name === RouteNames.HOME : false;
    }

    private mounted() {
        if (!this.currentRoute) {
            this.$store.dispatch(Actions.setRoute, this.$router.currentRoute);
        }
    }
}
