







































import { Component, Vue } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
import logoSvg from '@/../public/img/logo.svg?inline';
import { NavigationItem } from '@/constants/interfaces/Navigation';
import { RouteNames } from '@/constants/enums/routeNames';
import { Actions, Getters } from '@/constants/enums/store';
import { Route } from 'vue-router';
import { Getter } from 'vuex-class';
import { isMobile } from 'mobile-device-detect';
import { Slide } from 'vue-burger-menu';

@Component({
    name: ComponentName.HEADER_MOBILE,
    components: {
        logoSvg,
        Slide,
    },
})
export default class HeaderMobileComponent extends Vue {
    @Getter(Getters.currentRoute)
    private currentRoute!: Route;

    private isMobile = isMobile;
    private isDrawerOpen = false;

    private navigationItems: NavigationItem[] = [
        {
            label: 'Über mich',
            to: { name: RouteNames.ABOUT_ME },
        },
        {
            label: 'Beratung',
            to: { name: RouteNames.CONSULTATION },
        },
        {
            label: 'Startseite',
            to: { name: RouteNames.HOME },
        },
        {
            label: 'Service',
            to: { name: RouteNames.SERVICE },
        },
        {
            label: 'Kontakt',
            to: { name: RouteNames.CONTACT },
        },
    ];

    private openMenu() {
        this.isDrawerOpen = true;
    }

    private closeMenu() {
        this.isDrawerOpen = false;
    }

    private mounted() {
        if (!this.currentRoute) {
            this.$store.dispatch(Actions.setRoute, this.$router.currentRoute);
        }
    }
}
