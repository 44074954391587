












































import { Vue, Component } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
import logoSvg from '@/../public/img/logo.svg?inline';
import { isMobile } from 'mobile-device-detect';
import { RouteNames } from '@/constants/enums/routeNames';

@Component({
    name: ComponentName.BOTTOM_FOOTER,
    components: {
        logoSvg,
    },
})
export default class MsNavigationComponent extends Vue {
    private isMobile = isMobile;
    private RouteNames = RouteNames;
}
