















































































































import { Component, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/constants/enums/routeNames';
import InputField from '@/components/inputField.vue';
import ContentRow from '@/components/contentRow.vue';
import { isMobile } from 'mobile-device-detect';
import ContentCard from '@/components/contentCard.vue';
import { ContactForm, Salutation } from '@/constants/interfaces/ContactForm';
import TextArea from '@/components/textArea.vue';
import { SelectOption } from '@/constants/interfaces/SelectOption';
import SelectField from '@/components/selectField.vue';
import CheckboxField from '@/components/checkboxField.vue';
import emailjs from 'emailjs-com';
import Btn from '@/components/btn.vue';

@Component({
    name: RouteNames.CONTACT,
    components: { Btn, CheckboxField, SelectField, TextArea, ContentCard, ContentRow, InputField },
})
export default class Contact extends Vue {
    public $refs!: {
        hpot2: InputField;
    };
    private isMobile = isMobile;
    private isConsent = false;
    private isSending = false;
    private isInvalid = false;
    private isTimerActive = true;
    private contactData: ContactForm = {
        company: '',
        consent: false,
        hpot1: '',
        hpot2: '',
        mail: '',
        message: '',
        name: '',
        phone: '',
        preferredContact: null,
        salutation: null,
    };

    private salutationOptions: SelectOption[] = [
        {
            label: Salutation.HERR,
            value: Salutation.HERR,
        },
        {
            label: Salutation.FRAU,
            value: Salutation.FRAU,
        },
        {
            label: Salutation.DIVERS,
            value: Salutation.DIVERS,
        },
    ];

    private get selectedSalutation(): SelectOption | undefined {
        return this.salutationOptions.find((sourceValue) => sourceValue.value === this.contactData.salutation);
    }

    private get isDataValid(): boolean {
        return (
            this.isConsent &&
            this.contactData.name !== '' &&
            this.contactData.salutation !== null &&
            this.contactData.mail !== '' &&
            this.contactData.message !== '' &&
            this.contactData.hpot1 === '' &&
            this.contactData.hpot2 === '' &&
            !this.isTimerActive
        );
    }

    private onSalutationChange(val: SelectOption): void {
        this.contactData.salutation = val.value as Salutation;
    }

    private progressCountdown(timeLeft: number) {
        return new Promise((resolve) => {
            const countdownTimer = setInterval(() => {
                timeLeft--;
                if (timeLeft <= 0) {
                    clearInterval(countdownTimer);
                    resolve(true);
                }
            }, 1000);
        });
    }

    private onSubmit(): void {
        if (this.isDataValid) {
            this.isSending = true;
            emailjs
                .send('service_xpyhrce', 'test_contact_form_mail', this.contactData, 'user_ly54DmucnsGlWlxpGk4Bx')
                .then(() => {
                    this.$notify({
                        group: 'notifications',
                        type: 'success',
                        text: 'Die Kontaktanfrage wurde abgeschickt.',
                    });
                })
                .catch(() => {
                    this.$notify({
                        group: 'notifications',
                        type: 'error',
                        text: 'Beim Übersenden der Kontaktanfrage ist ein Fehler aufgetreten.',
                    });
                })
                .finally(() => {
                    this.isSending = false;
                });
        }
    }

    private mounted() {
        this.$refs.hpot2.$el.remove();
        this.progressCountdown(10).then(() => {
            this.isTimerActive = false;
        });
    }
}
